import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Location from '../../common/component/Location'
import DefaultWorkspace from '../component/DefaultWorkspace'
import TactoProjectList from '../component/TactoProjectList'
import TopicTreeList from '../component/TopicTreeList'
import MemoList from '../component/MemoList'
import TactoArticleList from '../component/TactoArticleList'
import MaterialList from '../component/MaterialList'
import WorkspaceTopSection from '../../common/component/WorkspaceTopSection'
import WorkspaceTactoProject from '../domain/WorkspaceTactoProject'
import {
  getMaterials,
  getMemos,
  getProjects,
  getRecentWorkspace,
  getTactoArticles,
  getTopicTrees,
} from '../repository/TactoWorkspaceRepository'
import { usePageable } from '../../common/hooks/usePageable'
import Pagination from '../../common/component/Pagination'
import WorkspaceTopicTree from '../domain/WorkspaceTopicTree'
import WorkspaceMemo from '../domain/WorkspaceMemo'
import WorkspaceTactoArticle from '../domain/WorkspaceTactoArticle'
import WorkspaceMaterial from '../domain/WorkspaceMaterial'
import ModalWindow from '../../common/component/ModalWindow'
import CreateProject from '../component/CreateProject'
import RecentWorkspaceItems from '../domain/RecentWorkspaceItems'
import { useSearchParams } from 'react-router-dom'
import SimpleManual from '../component/SimpleManual'

type Memu =
  | 'DefaultWorkspace'
  | 'TactoProject'
  | 'TopicTree'
  | 'Memo'
  | 'TactoArticle'
  | 'Material'
  | 'Trash'
  | 'SimpleManual'

const TactoWorkspace = () => {
  const { pageInfo, setupPage } = usePageable()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedMenu, setSelectedMenu] = useState<Memu>('DefaultWorkspace')
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isInTrash, setIsInTrash] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [recentWorkspace, setRecentWorkspace] = useState<RecentWorkspaceItems>({
    materials: [],
    memos: [],
    projects: [],
    tactoArticles: [],
    topics: [],
  })
  const [projects, setProjects] = useState<WorkspaceTactoProject[]>([])
  const [topicTrees, setTopicTrees] = useState<WorkspaceTopicTree[]>([])
  const [memos, setMemos] = useState<WorkspaceMemo[]>([])
  const [tactoArticles, setTactoArticles] = useState<WorkspaceTactoArticle[]>([])
  const [materials, setMaterials] = useState<WorkspaceMaterial[]>([])

  const [searchParams] = useSearchParams()

  const moveToPage = useCallback(
    (currentPage: number) => {
      switch (selectedMenu) {
        case 'TactoProject':
          getProjects(keyword, isInTrash, { size: 10, page: currentPage }).then((itemsPage) => {
            setProjects(itemsPage.content)
            setupPage(itemsPage, currentPage)
          })
          break
        case 'TopicTree':
          getTopicTrees(keyword, isInTrash, { size: 10, page: currentPage }).then((itemsPage) => {
            setTopicTrees(itemsPage.content)
            setupPage(itemsPage, currentPage)
          })
          break
        case 'Memo':
          getMemos(keyword, { size: 10, page: currentPage }).then((itemsPage) => {
            setMemos(itemsPage.content)
            setupPage(itemsPage, currentPage)
          })
          break
        case 'TactoArticle':
          getTactoArticles(keyword, isInTrash, { size: 10, page: currentPage }).then(
            (itemsPage) => {
              setTactoArticles(itemsPage.content)
              setupPage(itemsPage, currentPage)
            }
          )
          break
        case 'Material':
          getMaterials(keyword, isInTrash, { size: 10, page: currentPage }).then((itemsPage) => {
            setMaterials(itemsPage.content)
            setupPage(itemsPage, currentPage)
          })
          break
        default:
          getRecentWorkspace().then((workspace) => setRecentWorkspace(workspace))
          break
      }
    },
    [selectedMenu, keyword, isInTrash, setupPage]
  )

  const RenderedContent = useMemo(() => {
    switch (selectedMenu) {
      case 'TactoProject':
        return (
          <TactoProjectList
            projects={projects}
            isInTrash={isInTrash}
            refreshItems={() => moveToPage(pageInfo.currentPage)}
          />
        )
      case 'TopicTree':
        return (
          <TopicTreeList
            topicTrees={topicTrees}
            isInTrash={isInTrash}
            refreshItems={() => moveToPage(pageInfo.currentPage)}
          />
        )
      case 'Memo':
        return <MemoList memos={memos} />
      case 'TactoArticle':
        return (
          <TactoArticleList
            tactoArticles={tactoArticles}
            isInTrash={isInTrash}
            refreshItems={() => moveToPage(pageInfo.currentPage)}
          />
        )
      case 'Material':
        return (
          <MaterialList
            materials={materials}
            isInTrash={isInTrash}
            refreshItems={() => moveToPage(pageInfo.currentPage)}
          />
        )
      case 'SimpleManual':
        return <SimpleManual />
      default:
        return (
          <DefaultWorkspace
            recentWorkspace={recentWorkspace}
            openModal={() => setModalOpen(true)}
          />
        )
    }
  }, [
    selectedMenu,
    projects,
    isInTrash,
    topicTrees,
    memos,
    tactoArticles,
    materials,
    recentWorkspace,
    moveToPage,
    pageInfo,
  ])

  const onChangeMenu = useCallback((menu: Memu, inTrash: boolean) => {
    setKeyword('')
    setIsSearching(false)
    setSelectedMenu(menu)
    setIsInTrash(inTrash)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageInfo])

  useEffect(() => {
    moveToPage(0)
    // eslint-disable-next-line
  }, [selectedMenu, isInTrash])

  useEffect(() => {
    const menu = searchParams.get('menu')
    if (menu === 'trash') {
      onChangeMenu('TactoProject', true)
    } else if (menu === 'manual') {
      onChangeMenu('SimpleManual', false)
    }
  }, [onChangeMenu, searchParams])

  return (
    <main>
      <ModalWindow isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
        <CreateProject
          fetchProjects={() => moveToPage(pageInfo.currentPage)}
          closeModal={() => setModalOpen(false)}
        />
      </ModalWindow>
      <div className="container">
        <Location paths={['워크스페이스(beta)']} />
        <h1 className="head">워크스페이스</h1>
        <div className="content-wrap">
          <div className="aside">
            <div className="tacto-menus" aria-label="tacto-menus">
              <div
                className={`tacto-menu ${!isSearching && !isInTrash && selectedMenu === 'TactoProject' && 'on'}`}
                onClick={() => onChangeMenu('TactoProject', false)}
              >
                <div
                  className={`icon project ${!isSearching && !isInTrash && selectedMenu === 'TactoProject' && 'on'}`}
                />
                <div>프로젝트</div>
              </div>
              <div
                className={`tacto-menu ${!isSearching && !isInTrash && selectedMenu === 'TopicTree' && 'on'}`}
                onClick={() => onChangeMenu('TopicTree', false)}
              >
                <div
                  className={`icon tree ${!isSearching && !isInTrash && selectedMenu === 'TopicTree' && 'on'}`}
                />
                <div>토픽트리</div>
              </div>
              <div
                className={`tacto-menu ${!isSearching && !isInTrash && selectedMenu === 'Memo' && 'on'}`}
                onClick={() => onChangeMenu('Memo', false)}
              >
                <div
                  className={`icon memo ${!isSearching && !isInTrash && selectedMenu === 'Memo' && 'on'}`}
                />
                <div>메모</div>
              </div>
              <div
                className={`tacto-menu ${!isSearching && !isInTrash && selectedMenu === 'TactoArticle' && 'on'}`}
                onClick={() => onChangeMenu('TactoArticle', false)}
              >
                <div
                  className={`icon article ${!isSearching && !isInTrash && selectedMenu === 'TactoArticle' && 'on'}`}
                />
                <div>아티클</div>
              </div>
              <div
                className={`tacto-menu ${!isSearching && !isInTrash && selectedMenu === 'Material' && 'on'}`}
                onClick={() => onChangeMenu('Material', false)}
              >
                <div
                  className={`icon material ${!isSearching && !isInTrash && selectedMenu === 'Material' && 'on'}`}
                />
                <div>자료</div>
              </div>
              <div
                className={`tacto-menu ${isInTrash && 'on'}`}
                onClick={() => onChangeMenu('TactoProject', true)}
              >
                <div className={`icon trash ${isInTrash && 'on'}`} />
                <div>휴지통</div>
              </div>
              <div
                className={`tacto-menu ${!isSearching && !isInTrash && selectedMenu === 'SimpleManual' && 'on'}`}
                onClick={() => onChangeMenu('SimpleManual', false)}
              >
                <div
                  className={`icon manual ${!isSearching && !isInTrash && selectedMenu === 'SimpleManual' && 'on'}`}
                />
                <div>도움말</div>
              </div>
            </div>
          </div>
          <section className="content tacto-workspace">
            <div className="workspace-list">
              {recentWorkspace.projects.length > 0 && selectedMenu !== 'SimpleManual' && (
                <WorkspaceTopSection
                  keyword={keyword}
                  setKeyword={setKeyword}
                  search={() => {
                    setIsSearching(keyword !== '')
                    setSelectedMenu('TactoProject')
                    moveToPage(0)
                  }}
                  openModal={() => setModalOpen(true)}
                />
              )}
              {(isSearching || isInTrash) && (
                <div className="search-menus" aria-label="search-menu-tabs">
                  <div
                    className={`menu ${selectedMenu === 'TactoProject' && 'on'}`}
                    onClick={() => setSelectedMenu('TactoProject')}
                  >
                    프로젝트
                  </div>
                  <div
                    className={`menu ${selectedMenu === 'TopicTree' && 'on'}`}
                    onClick={() => setSelectedMenu('TopicTree')}
                  >
                    토픽트리
                  </div>
                  <div
                    className={`menu ${selectedMenu === 'TactoArticle' && 'on'}`}
                    onClick={() => setSelectedMenu('TactoArticle')}
                  >
                    아티클
                  </div>
                  <div
                    className={`menu ${selectedMenu === 'Material' && 'on'}`}
                    onClick={() => setSelectedMenu('Material')}
                  >
                    자료
                  </div>
                </div>
              )}
              {RenderedContent}
            </div>
            {selectedMenu !== 'DefaultWorkspace' && selectedMenu !== 'SimpleManual' && (
              <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
            )}
          </section>
        </div>
      </div>
    </main>
  )
}

export default TactoWorkspace
