import axios from 'axios'

import UserRepository from './UserRepository'
import UserInfo from '../domain/UserInfo'
import {
  clearUserToken,
  getUserToken,
  hasUserToken,
} from '../../common/repository/AccessTokenRepository'
import { ResetPasswordRequest } from '../domain/request/ResetPasswordRequest'

class AxiosUserRepository implements UserRepository {
  async isEmailDuplicated(email: string): Promise<boolean> {
    const { data } = await axios.get(`/auth/api/user/duplication?email=${email}`)
    return data.isDuplicated
  }

  isLoggedIn(): boolean {
    return hasUserToken()
  }

  logout(): void {
    clearUserToken()
    axios.get('/auth/api/user/logout')
  }

  parseUserInfo(): UserInfo | null {
    const token = getUserToken()
    try {
      if (token) {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )
        const result = JSON.parse(jsonPayload)
        return {
          id: +result.sub,
          email: result.email,
          nickName: result.nickName,
          level: result.roles[0],
          articlesStorages: [],
          authorities: result.roles,
        }
      }
    } catch {
      return null
    }
    return null
  }

  async resetPassword(email: string): Promise<void> {
    await axios.get('/auth/api/user/reset-password', {
      params: {
        email,
      },
    })
  }

  async validateResetPasswordToken(email: string, token: string): Promise<boolean> {
    const { data } = await axios.get('/auth/api/user/reset-token', {
      params: {
        email,
        token,
      },
    })
    return data
  }

  async changePassword(request: ResetPasswordRequest): Promise<void> {
    await axios.put('/auth/api/user/reset-password', request)
  }
}

export default AxiosUserRepository
