import UserInfo from '../../user/domain/UserInfo'
import { isAccessible } from './RoleHierarchy'
import { isPaymentEnabled } from './FeatureFlag'

export type RuleParams = {
  isLoggedIn: boolean
  userInfo: UserInfo | null
  path?: string
}

export const isAuthenticatedUser = ({ isLoggedIn, userInfo }: RuleParams) => {
  return isLoggedIn && userInfo !== null
}

export const isEditable = ({ isLoggedIn, userInfo, path }: RuleParams) => {
  if (path === '/bbs/edit/reports') {
    return isLoggedIn && isAccessible(userInfo?.level ?? '', 'NORMAL')
  }
  return isLoggedIn && isAccessible(userInfo?.level ?? '', 'EDITOR')
}

export const isKnotesUser = ({ isLoggedIn, userInfo }: RuleParams) => {
  if (!isPaymentEnabled()) {
    return true
  }
  if (isLoggedIn && isAccessible(userInfo?.level ?? '', 'EDITOR')) {
    return true
  }
  return isLoggedIn && userInfo?.authorities?.includes('KNOTES') === true
}
