const KnotesPricing = () => {
  return (
    <div>
      Knotes Pricing
      <h2>결제</h2>
    </div>
  )
}

export default KnotesPricing
