import workspaceImage from '../../styles/assets/image/workspace.png'
import workspaceProjectImage from '../../styles/assets/image/workspace_project.png'
import projectImage from '../../styles/assets/image/project.png'
import projectNewTopicImage from '../../styles/assets/image/project_new_topic.png'
import topicTreeImage from '../../styles/assets/image/topictree.png'
import topicTreeDocumentImage from '../../styles/assets/image/topictree_document.png'
import materialSearchImage from '../../styles/assets/image/material_search.png'
import materialImage from '../../styles/assets/image/material.png'
import articleImage from '../../styles/assets/image/article.png'
import articleWriteImage from '../../styles/assets/image/article_write.png'
import trashTopicImage from '../../styles/assets/image/trash_topic.png'
import trashMaterialImage from '../../styles/assets/image/trash_material.png'

const SimpleManual = () => {
  return (
    <div className="simple-manual">
      <div className="title-section">
        <div className="title">
          도움말<span>Beta Service</span>
        </div>
      </div>
      <div className="sections">
        <div className="section">
          <div className="title">케이노츠에 대하여</div>
          <div className="help">
            케이노츠(knotes)는 이지메타㈜가 개발한 연구자의 연구활동을 지원하는 도구입니다. 연구
            활동은 주제를 선정하고, 주제에 따라 세부 주제를 설계하면서 관련된 자료를 수집하는
            것으로부터 시작하여 최종적으로 논문/보고서를 작성합니다. 케이노츠에서는 연구 주제를
            관리하고 세부 주제별로 자료를 수집/관리할 수 있습니다. 세부 주제는 토픽트리라는 기능으로
            관리됩니다. 토픽트리를 작성하기 위해 여러가지 편리한 기능이 지원되며, 자료 수집도
            K-Discovery를 이용하여 수집/선정 시간을 절약해줍니다. 케이노츠는 아티클 기능을 통해
            초록의 작성도 지원합니다.
          </div>
        </div>
        <div className="section">
          <div className="title">워크스페이스</div>
          <div className="help">
            워크스페이스는 케이노츠의 활동공간입니다. 케이노츠의 모든 프로젝트는 워크스페이스 위에서
            사용되며, 프로젝트 목록, 토픽트리 목록, 자료 목록, 메모 목록 전체를 볼 수 있습니다.
          </div>
          <div className="images">
            <img src={workspaceImage} alt="image-1" />
            <img src={workspaceProjectImage} alt="image-2" />
          </div>
        </div>
        <div className="section">
          <div className="title">프로젝트</div>
          <div className="help">
            하나의 프로젝트에 하나의 토픽트리를 사용할 수 있으며, 토픽별로 자료를 관리할 수
            있습니다. 하나의 프로젝트에서 여러 개의 아티클을 작성할 수 있습니다. 프로젝트 생성
            레이어 팝업 화면이 열리면 프로젝트 이름, 설명을 입력하고 “만들기” 버튼을 클릭합니다.
            프로젝트를 처음 만들면 다음으로 토픽트리를 만들어야 합니다.
          </div>
          <div className="images">
            <img src={projectImage} alt="image-3" />
            <img src={projectNewTopicImage} alt="image-4" />
          </div>
        </div>
        <div className="section">
          <div className="title">토픽트리</div>
          <div className="help">
            토픽트리는 계층구조의 연구 세부 주제 목록입니다. 토픽트리 관리 레이어 팝업에서 직접
            추가/입력/수정 삭제하거나 기존 토픽트리 불러오기, 문서에서 추출하기, 토픽맵DB에서
            검색하기 등 다양한 방식으로 토픽트리를 만들 수 있습니다. 프로젝트 이름과 토픽트리 이름을
            다르게 설정할 수 있습니다.
          </div>
          <div className="images">
            <img src={topicTreeImage} alt="image-5" />
            <img src={topicTreeDocumentImage} alt="image-6" />
          </div>
        </div>
        <div className="section">
          <div className="title">자료 관리</div>
          <div className="help">
            케이노츠에서는 토픽에 기반하여 자료를 관리할 수 있습니다. k-discovery 서비스에서
            관리하는 데이터를 기준으로 편리하게 자료를 추가할 수 있으며, 다른 데이터도 등록할 수
            있습니다.
          </div>
          <div className="images">
            <img src={materialSearchImage} alt="image-7" />
            <img src={materialImage} alt="image-8" />
          </div>
        </div>
        <div className="section">
          <div className="title">아티클</div>
          <div className="help">
            아티클은 논문 초록 작성을 지원하는 기능입니다. 하나의 프로젝트에서 여러 개의 아티클을
            작성할 수 있습니다. 아티클 메뉴에서 “새 아티클” 버튼을 클릭합니다.
          </div>
          <div className="images">
            <img src={articleImage} alt="image-9" />
            <img src={articleWriteImage} alt="image-10" />
          </div>
        </div>
        <div className="section">
          <div className="title">휴지통</div>
          <div className="help">
            케이노프에서 삭제한 프로젝트, 토픽트리, 자료, 아티클은 휴지통에 저장되므로 복원할 수
            있습니다. 휴지통에 있는 프로젝트, 토픽트리, 자료, 아티클을 삭제하면 완전 삭제되어 복구할
            수 없습니다.
          </div>
          <div className="images">
            <img src={trashTopicImage} alt="image-11" />
            <img src={trashMaterialImage} alt="image-12" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleManual
